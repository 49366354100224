<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{ $t('Environmental Conditions') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('Survey Name*')" label-for="project_name">
                                                <b-form-select 
                                                v-model="selectedProjectId" 
                                                :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null" 
                                                aria-describedby="input-1-live-feedback" 
                                                :options="projectOptions" 
                                                value-field="id" 
                                                text-field="name"
                                                @change="onProjectChange">
                                                <b-form-select-option v-if="projectOptions.length === 0" :disabled="true">
                                                    {{ $t('No Project Found') }}
                                                </b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('survey-style*')" label-for="survey_style_name">
                                                <b-form-input
                                                type="text" 
                                                id="survey_style_name"
                                                name="survey_style_name"
                                                :placeholder="$t('survey-style')"
                                                v-model="form.survey_style_name" 
                                                readonly>
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                      <!--    <div class="col-md-4">
                                            <b-form-group :label="$t('project-id*')" label-for="project_id">
                                            <b-form-input
                                                type="text"
                                                id="project_id"
                                                name="project_id"
                                                autocomplete="new-password"
                                                :placeholder="$t('project-id*')"
                                                v-model="form.project_id" 
                                                :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null"
                                                readonly
                                                >
                                            </b-form-input>
                                            </b-form-group>
                                        </div>-->
                                    </div>
                                    <label for="weatherCodes">{{ $t('Use') }}</label>
                                    <div class="row project-checkbox">
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <input type="checkbox" v-model="form.is_weather_code_usable">
                                                    <label for="weatherCodes">{{ $t('Weather Code')}}</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group label-for="weatherCodes">
                                                        <b-form-select 
                                                        v-model="form.weather_code" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="weatherCodes" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="weatherCodes.length === 0" :disabled="true">
                                                            {{ $t('No Weather Code Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3">
                                                <b-button class="btn btn-success" @click="toggleInput('weatherCodes')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_beaufort_usable">
                                                    <label for="beauforts">{{ $t('Beaufort') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="beauforts">
                                                        <b-form-select 
                                                        v-model="form.beaufort" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="beauforts" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="beauforts.length === 0" :disabled="true">
                                                            {{ $t('No Beaufort Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('beauforts')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_glare_usable">
                                                    <label for="glares">{{ $t('Glare') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="glares">
                                                        <b-form-select 
                                                        v-model="form.glare" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="glares" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="glares.length === 0" :disabled="true">
                                                            {{ $t('No Glare Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('glares')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_glare_intensity_usable">
                                                    <label for="glareIntensity">{{ $t('Glare Intensity') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="glareIntensity">
                                                        <b-form-select 
                                                        v-model="form.glare_intensity" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="glareIntensity" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="glareIntensity.length === 0" :disabled="true">
                                                            {{ $t('No Glare Intensity Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('glareIntensity')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_glare_left_usable">
                                                    <label for="glareLeft">{{ $t('Glare Left') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="glareLeft">
                                                        <b-form-select 
                                                        v-model="form.glare_left" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="glareLeft" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="glareLeft.length === 0" :disabled="true">
                                                            {{ $t('No Glare Left Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('glareLeft')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_glare_right_usable">
                                                    <label for="glareRight">{{ $t('Glare Right') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="glareRight">
                                                        <b-form-select 
                                                        v-model="form.glare_right" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="glareRight" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="glareRight.length === 0" :disabled="true">
                                                            {{ $t('No Glare Right Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('glareRight')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_cloud_cover_usable">
                                                    <label for="cloudCover">{{ $t('Cloud Cover') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="cloudCover">
                                                        <b-form-select 
                                                        v-model="form.cloud_cover" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="cloudCover" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="cloudCover.length === 0" :disabled="true">
                                                            {{ $t('No Cloud Cover Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('cloudCover')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_quality_left_usable">
                                                    <label for="qualityLeft">{{ $t('Quality Left') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="qualityLeft">
                                                        <b-form-select 
                                                        v-model="form.quality_left" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="qualityLeft" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="qualityLeft.length === 0" :disabled="true">
                                                            {{ $t('No Quality Left Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('qualityLeft')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_quality_right_usable">
                                                    <label for="qualityRight">{{ $t('Quality Right') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="qualityRight">
                                                        <b-form-select 
                                                        v-model="form.quality_right" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="qualityRight" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="qualityRight.length === 0" :disabled="true">
                                                            {{ $t('No Quality Right Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('qualityRight')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_sun_penetration_usable">
                                                    <label for="sunPenetration">{{ $t('Sun Penetration') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="sunPenetration">
                                                        <b-form-select 
                                                        v-model="form.sun_penetration" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="sunPenetration" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="sunPenetration.length === 0" :disabled="true">
                                                            {{ $t('No Sun Penetration Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('sunPenetration')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_turbidity_usable">
                                                    <label for="turbidity">{{ $t('Turbidity') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="turbidity">
                                                        <b-form-select 
                                                        v-model="form.turbidity" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="turbidity" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="turbidity.length === 0" :disabled="true">
                                                            {{ $t('No Turbidity Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('turbidity')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <input type="checkbox" v-model="form.is_water_color_usable">
                                                    <label for="waterColor">{{ $t('Water Color') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="waterColor">
                                                        <b-form-select 
                                                        v-model="form.water_color" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="waterColor" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="waterColor.length === 0" :disabled="true">
                                                            {{ $t('No Water Color Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('waterColor')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-form-textarea 
                                                        v-model="inputValue" 
                                                        :placeholder="$t('Enter values separated by commas')"
                                                        rows="5"
                                                        >
                                                    </b-form-textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <label for=""><b>{{ $t('Data Entry Fields') }}</b></label>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="checkbox-radio d-flex align-items-center">
                                            <!-- Checkbox and Label for Wind Speed -->
                                            <div class="d-flex align-items-center" style="flex: 0;">
                                                <input type="checkbox" id="windSpeed" v-model="form.is_wind_speed_usable">
                                                <label for="windSpeed" class="ml-2" style="min-width: 150px;">{{ $t('Wind Speed') }}</label>
                                            </div>
                                            <!-- Radio buttons for units -->
                                            <div class="d-flex align-items-center ml-4">
                                                <input type="radio" id="windSpeedMph" v-model="form.wind_speed_unit" value="mph">
                                                <label for="windSpeedMph" class="ml-2" style="min-width: 60px; text-align: left;">{{ $t('Mph') }}</label>
                                            </div>
                                            <div class="d-flex align-items-center ml-4">
                                                <input type="radio" id="windSpeedKnots" v-model="form.wind_speed_unit" value="knots">
                                                <label for="windSpeedKnots" class="ml-2" style="min-width: 60px; text-align: left;">{{ $t('Knots') }}</label>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="checkbox-radio d-flex align-items-center">
                                            <!-- Checkbox and Label for Swell Height -->
                                            <div class="d-flex align-items-center" style="flex: 0;">
                                                <input type="checkbox" id="swellHeight" v-model="form.is_swell_height_usable">
                                                <label for="swellHeight" class="ml-2" style="min-width: 150px;">{{ $t('Swell Height') }}</label>
                                            </div>
                                            <!-- Radio buttons for units -->
                                            <div class="d-flex align-items-center ml-4">
                                                <input type="radio" id="swellHeightFeet" v-model="form.swell_height_unit" value="feet">
                                                <label for="swellHeightFeet" class="ml-2" style="min-width: 60px; text-align: left;">{{ $t('Feet') }}</label>
                                            </div>
                                            <div class="d-flex align-items-center ml-4">
                                                <input type="radio" id="swellHeightMeters" v-model="form.swell_height_unit" value="meters">
                                                <label for="swellHeightMeters" class="ml-2" style="min-width: 60px; text-align: left;">{{ $t('Meters') }}</label>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="checkbox-radio d-flex align-items-center">
                                            <!-- Checkbox and Label for Visibility -->
                                            <div class="d-flex align-items-center" style="flex: 0;">
                                                <input type="checkbox" id="visibility" v-model="form.is_visibility_usable">
                                                <label for="visibility" class="ml-2" style="min-width: 150px;">{{ $t('Visibility') }}</label>
                                            </div>
                                            <!-- Radio buttons for units -->
                                            <div class="d-flex align-items-center ml-4">
                                                <input type="radio" id="visibilityMiles" v-model="form.visibility_unit" value="miles">
                                                <label for="visibilityMiles" class="ml-2" style="min-width: 60px; text-align: left;">{{ $t('Mi') }}</label>
                                            </div>
                                            <div class="d-flex align-items-center ml-4">
                                                <input type="radio" id="visibilityNauticalMiles" v-model="form.visibility_unit" value="nautical_miles">
                                                <label for="visibilityNauticalMiles" class="ml-2" style="min-width: 60px; text-align: left;">{{ $t('NM') }}</label>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="checkbox-radio">
                                                <div class="checkbox-input">
                                                    <input type="checkbox" v-model="form.is_wind_direction_usable">
                                                    <label for="Wind Direction" class="ml-2" style="min-width: 150px;">{{ $t('Wind Direction') }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="checkbox-radio">
                                                <div class="checkbox-input">
                                                    <input type="checkbox" v-model="form.is_swell_direction_usable">
                                                    <label for="Swell Direction" class="ml-2" style="min-width: 150px;">{{ $t('Swell Direction') }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            <b-button type="submit" variant="success" :disabled="isDisabled" class="btn float-right btn-success">
                                                <span>{{ $t('submit-btn') }}</span>
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('Environmental Conditions') }}</h4>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :isLoading.sync="isLoading"
                            :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                            :rows="rows"
                            :columns="columns"
                            @on-search="searchFn"
                            :search-options="{enabled: true,searchFn: searchFn}"
                            :line-numbers="true">
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, survey_style, requiredIf, sameAs} from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    id: '',
                    project_name: '',
                    project_id: '',
                    survey_style_id: '',
                    survey_style_name: '',
                    weather_code:'',
                    beaufort:'',
                    glare:'',
                    glare_intensity:'',
                    glare_left: '',
                    glare_right: '',
                    cloud_cover: '',
                    swell_direction: '',
                    quality_left: '',
                    quality_right: '',
                    sun_penetration: '',
                    turbidity: '',
                    water_color: '',
                    wind_direction: '',
                    wind_speed: '',
                    swell_height: '',
                    visibility: '',
                    is_weather_code_usable: false,
                    is_beaufort_usable: false,
                    is_glare_usable: false,
                    is_glare_intensity_usable: false,
                    is_glare_left_usable: false,
                    is_glare_right_usable: false,
                    is_cloud_cover_usable: false,
                    is_swell_direction_usable: false,
                    is_quality_left_usable: false,
                    is_quality_right_usable: false,
                    is_sun_penetration_usable: false,
                    is_turbidity_usable: false,
                    is_water_color_usable: false,
                    is_wind_direction_usable: false,
                    is_wind_speed_usable: false,
                    is_swell_height_usable: false,
                    is_visibility_usable: false,
                    wind_speed_unit: '',
                    swell_height_unit: '',
                    visibility_unit: '',
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'asc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    // { label: 'Sighting Number', field: 'sighting_number' },
                    { label: 'Spot ID', field: 'spot_id' },
                    {
                        label: 'Project Name',
                        field: 'project_name',
                        filterable: true
                    },
                    { label: 'Weather Code', field: 'weather_code' },
                    { label: 'Beaufort', field: 'beaufort' },
                    { label: 'Glare', field: 'glare' },
                    { label: 'Glare Intensity', field: 'glare_intensity' },
                    { label: 'Glare Left', field: 'glare_left' },
                    { label: 'Glare Right', field: 'glare_right' },
                    { label: 'Cloud Cover', field: 'cloud_cover' },
                    { label: 'Swell Direction', field: 'swell_direction' },
                    { label: 'Quality Left', field: 'quality_left' },
                    { label: 'Quality Right', field: 'quality_right' },
                    { label: 'Sun Penetration', field: 'sun_penetration' },
                    { label: 'Turbidity', field: 'turbidity' },
                    { label: 'Water Color', field: 'water_color' },
                    { label: 'Wind Direction', field: 'wind_direction' },
                    { label: 'Wind Speed', field: 'wind_speed' },
                    { label: 'Swell Height', field: 'swell_height' },
                    { label: 'Visibility', field: 'visibility' },
                ],
                isDisabled: false,
                isLoading: false,
                rows: [],
                rowsForBulkEdit: [],
                totalRecords: 0,
                orders: [],
                projectOptions: [],
                weatherCodes: [],
                beauforts: [],
                glares: [],
                glareIntensity: [],
                glareLeft: [],
                glareRight: [],
                cloudCover: [],
                qualityLeft: [],
                qualityRight: [],
                sunPenetration: [],
                turbidity: [],
                waterColor: [],
                windDirection: [],
                selectedProjectId: null,
                showInput: false,
                inputValue: '',
                currentField: '',
                
            };
        },

        validations: {
            form: {
                id: {},
                project_name: {
                    required,
                }, 
                project_id: {
                    required,
                },
                survey_style_id: {
                    required,
                },
            },
        },
        mounted() {
            this.loadItems();
            this.form.id = this.$route.params.id;
            if (this.form.id && this.form.id !== 'undefined') {
                this.readonly = true;
                this.getSpotter();
            } else {
                this.readonly = false;
            }
            this.isLoading = true;
            // this.inputValue = this.weatherCodesName;
            this.getNames();
        },
        methods: {
            toggleInput(field) {
                this.currentField = field;
                // consele
                if (field === 'weatherCodes') {
                    this.inputValue = this.weatherCodesName;
                }
                else if(field === 'beauforts'){
                    this.inputValue = this.beaufortNames;
                }
                else if(field === 'glares'){
                    this.inputValue = this.glareNames;
                }
                else if(field === 'glareIntensity'){
                    this.inputValue = this.glareIntensityNames;
                }
                else if(field === 'glareLeft'){
                    this.inputValue = this.glareLeftNames;
                }
                else if(field === 'glareRight'){
                    this.inputValue = this.glareRightNames;
                }
                else if(field === 'cloudCover'){
                    this.inputValue = this.cloudCoverNames;
                }
                else if(field === 'qualityLeft'){
                    this.inputValue = this.qualityLeftNames;
                }
                else if(field === 'qualityRight'){
                    this.inputValue = this.qualityRightNames;
                }
                else if(field === 'sunPenetration'){
                    this.inputValue = this.sunPenetrationNames;
                }
                else if(field === 'turbidity'){
                    this.inputValue = this.turbidityNames;
                }
                else if(field === 'waterColor'){
                    this.inputValue = this.waterColorNames;
                }
            },
            onSubmit() {
                if (!this.form.project_id) {
                    this.isDisabled = true;
                    this.$snotify.warning('Please fill in the project name.', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }
                let data = {
                    project_id: this.selectedProjectId,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    is_weather_code_usable : this.form.is_weather_code_usable,
                    is_beaufort_usable : this.form.is_beaufort_usable,
                    is_glare_usable : this.form.is_glare_usable,
                    is_glare_intensity_usable : this.form.is_glare_intensity_usable,
                    is_glare_left_usable : this.form.is_glare_left_usable,
                    is_glare_right_usable : this.form.is_glare_right_usable,
                    is_cloud_cover_usable : this.form.is_cloud_cover_usable,
                    is_swell_direction_usable : this.form.is_swell_direction_usable,
                    is_quality_left_usable : this.form.is_quality_left_usable,
                    is_quality_right_usable : this.form.is_quality_right_usable,
                    is_sun_penetration_usable : this.form.is_sun_penetration_usable,
                    is_turbidity_usable : this.form.is_turbidity_usable,
                    is_water_color_usable : this.form.is_water_color_usable,
                    is_wind_direction_usable : this.form.is_wind_direction_usable,
                    is_wind_speed_usable : this.form.is_wind_speed_usable,
                    is_swell_height_usable : this.form.is_swell_height_usable,
                    is_visibility_usable : this.form.is_visibility_usable,
                    wind_speed_unit: this.form.wind_speed_unit,
                    swell_height_unit: this.form.swell_height_unit,
                    visibility_unit: this.form.visibility_unit,
                };
                if (this.currentField === 'weatherCodes') {
                    data.weather_code = this.inputValue;
                } 
                else if (this.currentField === 'beauforts') {
                    data.beaufort = this.inputValue;
                } 
                else if (this.currentField === 'glares') {
                    data.glare = this.inputValue;
                }
                else if (this.currentField === 'glareIntensity') {
                    data.glare_intensity = this.inputValue;
                }
                else if (this.currentField === 'glareLeft') {
                    data.glare_left = this.inputValue;
                }
                else if (this.currentField === 'glareRight') {
                    data.glare_right = this.inputValue;
                }
                else if (this.currentField === 'cloudCover') {
                    data.cloud_cover = this.inputValue;
                }
                else if (this.currentField === 'qualityLeft') {
                    data.quality_left = this.inputValue;
                }
                else if (this.currentField === 'qualityRight') {
                    data.quality_right = this.inputValue;
                }
                else if (this.currentField === 'sunPenetration') {
                    data.sun_penetration = this.inputValue;
                }
                else if (this.currentField === 'turbidity') {
                    data.turbidity = this.inputValue;
                }
                else if (this.currentField === 'waterColor') {
                    data.water_color = this.inputValue;
                }
                API.addEditProjectWeather(
                    data,
                    (data) => {
                        this.isDisabled = false;
                        this.$v.form.$reset();
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        this.inputValue = '';
                        this.currentField = '';
                        this.getWeatherData(this.selectedProjectId);
                    },
                    // (err) => {
                    //     this.isDisabled = false;
                    //     this.$snotify.error(err, {
                    //         timeout: 2000,
                    //         showProgressBar: false,
                    //         closeOnClick: true,
                    //         pauseOnHover: true,
                    //     });
                    // }
                );
            },
            getNames() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.getProjectName(
                    data,
                    response => {
                    this.projectOptions = response.data.map(project => ({
                        id: project.id,
                        name: project.project_name
                    }));
                        if (this.projectOptions.length > 0) {
                            this.selectedProjectId = this.projectOptions[0].id;
                            this.getProjectStyleData(this.selectedProjectId);
                            this.getWeatherData(this.selectedProjectId);
                            this.toggleInput();
                        }
                    },
                    err => {
                    console.error('Error fetching project names:', err);
                    }
                );
            },
            getProjectStyleData(projectId) {
                API.getProjectSetup(
                    { projectId },
                    response => {
                        const project = response.data;
                        this.form.project_id = project.project_id;
                        this.form.survey_style_id = project.survey_style_id;
                        this.form.survey_style_name = project.survey_style ? project.survey_style.name : '';
                    },
                    err => {
                    console.error('Error fetching project details:', err);
                    }
                );
            },
            getWeatherData(projectId) {
                API.getProjectWeatherDetails({ projectId }, response => {
                    const projects = response.data;

                    this.weatherCodes = [];
                    this.beauforts = [];
                    this.glares = [];
                    this.glareIntensity = [];
                    this.glareLeft = [];
                    this.glareRight = [];
                    this.cloudCover = [];
                    this.qualityLeft = [];
                    this.qualityRight = [];
                    this.sunPenetration = [];
                    this.turbidity = [];
                    this.waterColor = [];

                    if (projects.length > 0) {
                        projects.forEach(project => {
                            const { key, values, is_usable, wind_speed_unit, swell_height_unit, visibility_unit } = project;
                            const valueArray = values ? values.split(',').map(val => val.trim()) : [];

                            switch (key) {
                                case 'weather_code':
                                    this.weatherCodes = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_weather_code_usable = !!is_usable;
                                    break;
                                case 'beaufort':
                                    this.beauforts = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_beaufort_usable = !!is_usable;
                                    break;
                                case 'glare':
                                    this.glares = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_glare_usable = !!is_usable;
                                    break;
                                case 'glare_intensity':
                                    this.glareIntensity = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_glare_intensity_usable = !!is_usable;
                                    break;
                                case 'glare_left':
                                    this.glareLeft = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_glare_left_usable = !!is_usable;
                                    break;
                                case 'glare_right':
                                    this.glareRight = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_glare_right_usable = !!is_usable;
                                    break;
                                case 'cloud_cover':
                                    this.cloudCover = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_cloud_cover_usable = !!is_usable;
                                    break;
                                case 'swell_direction':
                                    this.form.is_swell_direction_usable = !!is_usable;
                                    break;
                                case 'quality_left':
                                    this.qualityLeft = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_quality_left_usable = !!is_usable;
                                    break;
                                case 'quality_right':
                                    this.qualityRight = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_quality_right_usable = !!is_usable;
                                    break;
                                case 'sun_penetration':
                                    this.sunPenetration = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_sun_penetration_usable = !!is_usable;
                                    break;
                                case 'turbidity':
                                    this.turbidity = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_turbidity_usable = !!is_usable;
                                    break;
                                case 'water_color':
                                    this.waterColor = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_water_color_usable = !!is_usable;
                                    break;
                                case 'wind_direction':
                                    this.form.is_wind_direction_usable = !!is_usable;
                                    break;
                                case 'wind_speed':
                                    this.form.is_wind_speed_usable = !!is_usable;
                                    break;
                                case 'swell_height':
                                    this.form.is_swell_height_usable = !!is_usable;
                                    break;
                                case 'visibility':
                                    this.form.is_visibility_usable = !!is_usable;
                                    break;
                            }
                            if (wind_speed_unit) {
                                this.form.wind_speed_unit = wind_speed_unit;
                            }
                            if (swell_height_unit) {
                                this.form.swell_height_unit = swell_height_unit;
                            }
                            if (visibility_unit) {
                                this.form.visibility_unit = visibility_unit;
                            }
                        });
                    }
                    else{
                        this.form.is_weather_code_usable = false;
                        this.form.is_beaufort_usable = false;
                        this.form.is_glare_usable = false;
                        this.form.is_glare_intensity_usable = false;
                        this.form.is_glare_left_usable = false;
                        this.form.is_glare_right_usable = false;
                        this.form.is_cloud_cover_usable = false;
                        this.form.is_swell_direction_usable = false;
                        this.form.is_quality_left_usable = false;
                        this.form.is_quality_right_usable = false;
                        this.form.is_sun_penetration_usable = false;
                        this.form.is_turbidity_usable = false;
                        this.form.is_water_color_usable = false;
                        this.form.is_wind_direction_usable = false;
                        this.form.is_wind_speed_usable = false;
                        this.form.is_swell_height_usable = false;
                        this.form.is_visibility_usable = false;
                        this.form.wind_speed_unit = false;
                        this.form.swell_height_unit = false;
                        this.form.visibility_unit = false;
                    }
                }, err => {
                    console.error('Error fetching project weather details:', err);
                });
            },
            onProjectChange(selectedProjectId) {
                if (selectedProjectId) {
                    this.getProjectStyleData(selectedProjectId);
                    this.getWeatherData(selectedProjectId);
                    this.toggleInput();
                }
            },
            loadItems() {
                this.isLoading = true;
                console.log('Fetching Efforts Data with params:', this.serverParams);
                API.getWeathers(this.serverParams,
                    data => {
                        console.log('Fetched Data:', data);
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data;
                        console.log('Rows:', this.rows);
                    },
                    err => {
                        this.isLoading = false;
                        console.error('Error fetching data:', err);
                    }
                )
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
                if (status === 1) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
            status(status) {
                if (status === 'A') {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
        }, 
        computed: {
            weatherCodesName: {
                get() {
                    return this.weatherCodes.map(code => code.name).join(', ');
                },
                set(newValue) {
                    const weatherCodes = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.weatherCodes.find(code => code.name === name.trim()).id };
                    });
                    this.form.weather_code = weatherCodes;
                }
            },
            beaufortNames: {
                get() {
                    return this.beauforts.map(beaufort => beaufort.name).join(', ');
                },
                set(newValue) {
                    const beauforts = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.beauforts.find(beaufort => beaufort.name === name.trim()).id };
                    });
                    this.form.beaufort = beauforts;
                }
            },
            glareNames: {
                get() {
                    return this.glares.map(glare => glare.name).join(', ');
                },
                set(newValue) {
                    const glares = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.glares.find(glare => glare.name === name.trim()).id };
                    });
                    this.form.glare = glares;
                }
            },
            glareIntensityNames: {
                get() {
                    return this.glareIntensity.map(glare => glare.name).join(', ');
                },
                set(newValue) {
                    const glareIntensity = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.glareIntensity.find(glare => glare.name === name.trim()).id };
                    });
                    this.form.glare_intensity = glareIntensity;
                }
            },
            glareLeftNames: {
                get() {
                    return this.glareLeft.map(glare => glare.name).join(', ');
                },
                set(newValue) {
                    const glareLeft = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.glareLeft.find(glare => glare.name === name.trim()).id };
                    });
                    this.form.glare_left = glareLeft;
                }
            },
            glareRightNames: {
                get() {
                    return this.glareRight.map(glare => glare.name).join(', ');
                },
                set(newValue) {
                    const glareRight = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.glareRight.find(glare => glare.name === name.trim()).id };
                    });
                    this.form.glare_right = glareRight;
                }
            },
            cloudCoverNames: {
                get() {
                    return this.cloudCover.map(cloud => cloud.name).join(', ');
                },
                set(newValue) {
                    const cloudCover = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.cloudCover.find(cloud => cloud.name === name.trim()).id };
                    });
                    this.form.cloud_cover = cloudCover;
                }
            },
            qualityLeftNames: {
                get() {
                    return this.qualityLeft.map(quality => quality.name).join(', ');
                },
                set(newValue) {
                    const qualityLeft = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.qualityLeft.find(quality => quality.name === name.trim()).id };
                    });
                    this.form.quality_left = qualityLeft;
                }
            },
            qualityRightNames: {
                get() {
                    return this.qualityRight.map(quality => quality.name).join(', ');
                },
                set(newValue) {
                    const qualityRight = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.qualityRight.find(quality => quality.name === name.trim()).id };
                    });
                    this.form.quality_right = qualityRight;
                }
            },
            sunPenetrationNames: {
                get() {
                    return this.sunPenetration.map(penetration => penetration.name).join(', ');
                },
                set(newValue) {
                    const sunPenetration = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.sunPenetration.find(penetration => penetration.name === name.trim()).id };
                    });
                    this.form.sun_penetration = sunPenetration;
                }
            },
            turbidityNames: {
                get() {
                    return this.turbidity.map(t => t.name).join(', ');
                },
                set(newValue) {
                    const turbidity = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.turbidity.find(t => t.name === name.trim()).id };
                    });
                    this.form.turbidity = turbidity;
                }
            },
            waterColorNames: {
                get() {
                    return this.waterColor.map(w => w.name).join(', ');
                },
                set(newValue) {
                    const waterColor = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.waterColor.find(w => w.name === name.trim()).id };
                    });
                    this.form.water_color = waterColor;
                }
            },

        },
    }
</script>

<style>
    .btn-duplicate {
        width: 80px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
    .checkbox-radio {
        display: flex;
        gap: 35px;
    }
    .checkbox-radio label {
        padding-left: 7px;
    }
    .card-body.new-card-body select {
        border: 1px solid #000;
        border-radius: 0;
        height: 46px;
    }
    .project-checkbox label {
        padding-left: 15px;
    }
    .project-checkbox textarea {
        border: 1px solid #000 !important;
        height: 250px;
        outline: none;
        box-shadow: none;
        border-radius: 0;
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>
